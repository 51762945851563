import {
  TagFragment,
  useCreateContentTagMembershipsMutation,
  useCreateTagMutation,
  useDeleteContentTagMembershipsMutation,
  useDeleteTagMutation,
  useTagsQuery,
  useUpdateTagMutation,
} from "@contexts/TagContext/TagContext.generated";
import { useToast } from "@hooks/useToast";
import {
  ContentTagMembershipInput,
  GraphqlOperations,
  TagInput,
} from "@src/types.generated";
import gql from "graphql-tag";
import React, { FC, useEffect, useMemo, useState } from "react";

export interface TagContextState {
  tags: TagFragment[];
  tagMap: Map<string, TagFragment>;
  createTag: (
    id: string,
    tag: TagInput,
    onCreateCompleteCallback?: (createdTag: TagFragment) => void,
  ) => void;
  deleteTag: (id: string) => void;
  editTag: (id: string, tag: TagInput) => void;
  createContentTagMemberships: (inputs: ContentTagMembershipInput[]) => void;
  deleteContentTagMemberships: (inputs: ContentTagMembershipInput[]) => void;
}

export const TagContext = React.createContext<TagContextState | undefined>(
  undefined,
);

export const LIBRARY_PAGINATED_TABLE_REFETCH_QUERIES = [
  GraphqlOperations.Query.CourseLibraryItemPaginatedTable_LibraryItem,
  GraphqlOperations.Query.ModulesLibraryPaginatedTable_LibraryItems,
  GraphqlOperations.Query.CheckinsLibraryPaginatedTable_LibraryItems,
  GraphqlOperations.Query.TasksLibraryPaginatedTable_LibraryItems,
  GraphqlOperations.Query.ResourcesLibraryPaginatedTable_LibraryItems,
  GraphqlOperations.Query.ArchiveLibraryPaginatedTable_LibraryItems,
];

export const CONTENT_LIBRARY_REFETCH_QUERIES = [
  ...LIBRARY_PAGINATED_TABLE_REFETCH_QUERIES,
  GraphqlOperations.Query.Tags,
  GraphqlOperations.Query.LibraryItemDetailPage_LibraryItem,
  GraphqlOperations.Query.TagDropdownContentTags,
];

type Props = {
  children: React.ReactNode;
};

const TAG_CONTEXT_STATE_REFETCH_QUERIES = [
  ...CONTENT_LIBRARY_REFETCH_QUERIES,
  GraphqlOperations.Query.Tags,
  GraphqlOperations.Query.LibraryItemDetailPage_LibraryItem,
  GraphqlOperations.Query.TagDropdownContentTags,
  GraphqlOperations.Query.TagsPageContentTags,
  GraphqlOperations.Query.ProgressiveTagFilterTags,
];

const TagContextProvider: FC<Props> = ({ children }) => {
  const { data } = useTagsQuery();
  const [tags, setTags] = useState(data?.AdminTags || []);
  const tagMap: Map<string, TagFragment> = useMemo(() => {
    return new Map(tags.map((x) => [x.id, x]));
  }, [tags]);
  useEffect(() => {
    if (data?.AdminTags && data?.AdminTags.length > 0) {
      setTags(data.AdminTags);
    }
  }, [data?.AdminTags]);
  const { addErrorToast } = useToast();
  const [createTagMutation] = useCreateTagMutation({
    refetchQueries: TAG_CONTEXT_STATE_REFETCH_QUERIES,
  });
  const [updateTagMutation] = useUpdateTagMutation({
    refetchQueries: TAG_CONTEXT_STATE_REFETCH_QUERIES,
    onCompleted(data) {
      if (!data.updateTag.success) {
        if (data.updateTag.error?.code !== "DOES_NOT_EXIST") {
          addErrorToast(data);
        }
      }
    },
    onError() {
      addErrorToast({ callsite: "tag_context" });
    },
  });
  const [deleteTagMutation] = useDeleteTagMutation({
    refetchQueries: TAG_CONTEXT_STATE_REFETCH_QUERIES,
    onCompleted(data) {
      if (!data.deleteTag.success) {
        addErrorToast(data);
      }
    },
    onError() {
      addErrorToast({ callsite: "tag_context" });
    },
  });
  const [createContentTagMembershipMutation] =
    useCreateContentTagMembershipsMutation({
      refetchQueries: TAG_CONTEXT_STATE_REFETCH_QUERIES,
      onCompleted(data) {
        if (!data.createContentTagMemberships.success) {
          addErrorToast(data);
        }
      },
      onError() {
        addErrorToast({ callsite: "tag_context" });
      },
      optimisticResponse(vars) {
        // @ts-ignore
        const newContentTagMems = vars.inputs.map(
          (x: ContentTagMembershipInput) => ({
            __typename: "ContentTagMembership",
            tagId: x.tagId,
            libraryItemId: x.libraryItemId,
            tag: {
              id: x.tagId,
              nameTranslations: tagMap.get(x.tagId)?.nameTranslations,
            },
          }),
        );
        return {
          createContentTagMemberships: {
            success: true,
            contentTagMemberships: newContentTagMems,
          },
        };
      },
    });
  const [deleteContentTagMembershipMutation] =
    useDeleteContentTagMembershipsMutation({
      refetchQueries: TAG_CONTEXT_STATE_REFETCH_QUERIES,
      onCompleted(data) {
        if (!data.deleteContentTagMemberships.success) {
          addErrorToast(data);
        }
      },
      onError() {
        addErrorToast({ callsite: "tag_context" });
      },
      optimisticResponse() {
        return {
          deleteContentTagMemberships: {
            success: true,
          },
        };
      },
    });
  const state: TagContextState = useMemo(() => {
    return {
      tags: tags,
      tagMap: tagMap,
      createTag(id, input, callback) {
        createTagMutation({
          variables: { id: id, input: input },
        }).then((res) => {
          if (res.data?.createTag.success && res.data.createTag.tag) {
            const createdTag = res.data.createTag.tag;
            setTags((prevState) => [createdTag, ...prevState]);
            if (callback) {
              callback(createdTag);
            }
          }
        });
      },
      deleteTag(id) {
        setTags((prevState) => {
          return prevState.filter((t) => t.id !== id);
        });
        deleteTagMutation({
          variables: { id },
        });
      },
      editTag(id, input) {
        setTags((prevState) => {
          const tag = prevState.find((t) => t.id === id);
          if (!tag) {
            return prevState;
          }
          const updatedTag: TagFragment = {
            ...tag,
            ...input,
            availableInApp: input.availableInApp ?? false,
            pinned: input.pinned?.value ?? false,
            nameTranslations: {
              ...tag.nameTranslations,
              ...input.nameTranslations,
              translationsDisabled:
                input.nameTranslations.translationsDisabled || false,
              translationOverrides:
                tag.nameTranslations.translationOverrides.map((x) => ({
                  ...x,
                  ...input.nameTranslations.translationOverrides?.find(
                    (y) => y.language === x.language,
                  ),
                })),
            },
          };
          return prevState.map((t) => (t.id === tag.id ? updatedTag : t));
        });
        updateTagMutation({
          variables: { id: id, input: input },
        });
      },
      createContentTagMemberships(inputs) {
        createContentTagMembershipMutation({
          variables: {
            inputs,
          },
        });
      },
      deleteContentTagMemberships(inputs) {
        deleteContentTagMembershipMutation({
          variables: {
            inputs,
          },
        });
      },
    };
  }, [
    tags,
    tagMap,
    createContentTagMembershipMutation,
    createTagMutation,
    deleteContentTagMembershipMutation,
    deleteTagMutation,
    updateTagMutation,
  ]);
  return <TagContext.Provider value={state}>{children}</TagContext.Provider>;
};

export default TagContextProvider;

gql`
  query Tags {
    AdminTags {
      ...Tag
    }
  }
  mutation createTag($id: String!, $input: TagInput!) {
    createTag(id: $id, input: $input) {
      success
      tag {
        ...Tag
      }
    }
  }
  mutation updateTag($id: String!, $input: TagInput!) {
    updateTag(id: $id, input: $input) {
      success
      error {
        message
        code
      }
      tag {
        ...Tag
      }
    }
  }
  mutation deleteTag($id: String!) {
    deleteTag(id: $id) {
      success
    }
  }
  mutation createContentTagMemberships($inputs: [ContentTagMembershipInput!]!) {
    createContentTagMemberships(inputs: $inputs) {
      success
      contentTagMemberships {
        tagId
        libraryItemId
        tag {
          id
          nameTranslations {
            ...TranslationSet
          }
        }
      }
    }
  }
  mutation deleteContentTagMemberships($inputs: [ContentTagMembershipInput!]!) {
    deleteContentTagMemberships(inputs: $inputs) {
      success
    }
  }

  fragment Tag on Tag {
    id
    nameTranslations {
      ...TranslationSet
    }
    availableInApp
    pinned
  }
  query CatalogPath($id: String!) {
    CatalogPath(id: $id) {
      ...LibraryCatalogPath
    }
  }
`;
