import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../fragments.generated';
import { LibraryCatalogPathFragmentDoc } from '../../components/library/LibraryPaginatedTable.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', AdminTags: Array<{ __typename?: 'Tag', id: string, availableInApp: boolean, pinned?: boolean | null, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } }> };

export type CreateTagMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  input: Types.TagInput;
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'TagMutationResult', success: boolean, tag?: { __typename?: 'Tag', id: string, availableInApp: boolean, pinned?: boolean | null, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } | null } };

export type UpdateTagMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  input: Types.TagInput;
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', updateTag: { __typename?: 'TagMutationResult', success: boolean, error?: { __typename?: 'Error', message?: string | null, code: string } | null, tag?: { __typename?: 'Tag', id: string, availableInApp: boolean, pinned?: boolean | null, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } | null } };

export type DeleteTagMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteTagMutation = { __typename?: 'Mutation', deleteTag: { __typename?: 'TagMutationResult', success: boolean } };

export type CreateContentTagMembershipsMutationVariables = Types.Exact<{
  inputs: Array<Types.ContentTagMembershipInput> | Types.ContentTagMembershipInput;
}>;


export type CreateContentTagMembershipsMutation = { __typename?: 'Mutation', createContentTagMemberships: { __typename?: 'ContentTagMembershipMutationResult', success: boolean, contentTagMemberships?: Array<{ __typename?: 'ContentTagMembership', tagId: string, libraryItemId: string, tag: { __typename?: 'Tag', id: string, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } }> | null } };

export type DeleteContentTagMembershipsMutationVariables = Types.Exact<{
  inputs: Array<Types.ContentTagMembershipInput> | Types.ContentTagMembershipInput;
}>;


export type DeleteContentTagMembershipsMutation = { __typename?: 'Mutation', deleteContentTagMemberships: { __typename?: 'ContentTagMembershipMutationResult', success: boolean } };

export type TagFragment = { __typename?: 'Tag', id: string, availableInApp: boolean, pinned?: boolean | null, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } };

export type CatalogPathQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CatalogPathQuery = { __typename?: 'Query', CatalogPath?: { __typename?: 'CatalogPath', id: string, path: { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, publishedVersion?: { __typename?: 'PathVersion', id: string } | null, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string } } } } | null };

export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  nameTranslations {
    ...TranslationSet
  }
  availableInApp
  pinned
}
    ${TranslationSetFragmentDoc}`;
export const TagsDocument = gql`
    query Tags {
  AdminTags {
    ...Tag
  }
}
    ${TagFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($id: String!, $input: TagInput!) {
  createTag(id: $id, input: $input) {
    success
    tag {
      ...Tag
    }
  }
}
    ${TagFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($id: String!, $input: TagInput!) {
  updateTag(id: $id, input: $input) {
    success
    error {
      message
      code
    }
    tag {
      ...Tag
    }
  }
}
    ${TagFragmentDoc}`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation deleteTag($id: String!) {
  deleteTag(id: $id) {
    success
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const CreateContentTagMembershipsDocument = gql`
    mutation createContentTagMemberships($inputs: [ContentTagMembershipInput!]!) {
  createContentTagMemberships(inputs: $inputs) {
    success
    contentTagMemberships {
      tagId
      libraryItemId
      tag {
        id
        nameTranslations {
          ...TranslationSet
        }
      }
    }
  }
}
    ${TranslationSetFragmentDoc}`;
export type CreateContentTagMembershipsMutationFn = Apollo.MutationFunction<CreateContentTagMembershipsMutation, CreateContentTagMembershipsMutationVariables>;

/**
 * __useCreateContentTagMembershipsMutation__
 *
 * To run a mutation, you first call `useCreateContentTagMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentTagMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentTagMembershipsMutation, { data, loading, error }] = useCreateContentTagMembershipsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateContentTagMembershipsMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentTagMembershipsMutation, CreateContentTagMembershipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentTagMembershipsMutation, CreateContentTagMembershipsMutationVariables>(CreateContentTagMembershipsDocument, options);
      }
export type CreateContentTagMembershipsMutationHookResult = ReturnType<typeof useCreateContentTagMembershipsMutation>;
export type CreateContentTagMembershipsMutationResult = Apollo.MutationResult<CreateContentTagMembershipsMutation>;
export type CreateContentTagMembershipsMutationOptions = Apollo.BaseMutationOptions<CreateContentTagMembershipsMutation, CreateContentTagMembershipsMutationVariables>;
export const DeleteContentTagMembershipsDocument = gql`
    mutation deleteContentTagMemberships($inputs: [ContentTagMembershipInput!]!) {
  deleteContentTagMemberships(inputs: $inputs) {
    success
  }
}
    `;
export type DeleteContentTagMembershipsMutationFn = Apollo.MutationFunction<DeleteContentTagMembershipsMutation, DeleteContentTagMembershipsMutationVariables>;

/**
 * __useDeleteContentTagMembershipsMutation__
 *
 * To run a mutation, you first call `useDeleteContentTagMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentTagMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentTagMembershipsMutation, { data, loading, error }] = useDeleteContentTagMembershipsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useDeleteContentTagMembershipsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentTagMembershipsMutation, DeleteContentTagMembershipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentTagMembershipsMutation, DeleteContentTagMembershipsMutationVariables>(DeleteContentTagMembershipsDocument, options);
      }
export type DeleteContentTagMembershipsMutationHookResult = ReturnType<typeof useDeleteContentTagMembershipsMutation>;
export type DeleteContentTagMembershipsMutationResult = Apollo.MutationResult<DeleteContentTagMembershipsMutation>;
export type DeleteContentTagMembershipsMutationOptions = Apollo.BaseMutationOptions<DeleteContentTagMembershipsMutation, DeleteContentTagMembershipsMutationVariables>;
export const CatalogPathDocument = gql`
    query CatalogPath($id: String!) {
  CatalogPath(id: $id) {
    ...LibraryCatalogPath
  }
}
    ${LibraryCatalogPathFragmentDoc}`;

/**
 * __useCatalogPathQuery__
 *
 * To run a query within a React component, call `useCatalogPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogPathQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogPathQuery(baseOptions: Apollo.QueryHookOptions<CatalogPathQuery, CatalogPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogPathQuery, CatalogPathQueryVariables>(CatalogPathDocument, options);
      }
export function useCatalogPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogPathQuery, CatalogPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogPathQuery, CatalogPathQueryVariables>(CatalogPathDocument, options);
        }
export type CatalogPathQueryHookResult = ReturnType<typeof useCatalogPathQuery>;
export type CatalogPathLazyQueryHookResult = ReturnType<typeof useCatalogPathLazyQuery>;
export type CatalogPathQueryResult = Apollo.QueryResult<CatalogPathQuery, CatalogPathQueryVariables>;